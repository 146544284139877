.header_technohood {
    /* background-color: #022A44;  */
    z-index: 1;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
}

.header_technohood .header-wrapper {
    width: 93.5%;
}

.header_technohood .header-wrapper .navbar .navbar-container {
    width: 100%;
}
.header_technohood .header-wrapper .navbar .navbar-container .nav-logo{
  width: 130px;
  cursor: pointer;
}

.header_technohood .main-nav {
    list-style-type: none;
    margin: 0;
}

.header_technohood .nav-links,
.logo {
    text-decoration: none;
    color:  #ffffff;
    font-size: 14px;
}

.header_technohood .nav-links:active,
.logo:active {
    color: #ffffff;
    font-weight: 400;
}

.header_technohood .nav-links:focus,
.logo:focus {
    color: #ffffff;
    font-weight: 400;
}

.header_technohood .main-nav li {
    text-align: center;
}
.header_technohood .nav-list .nav-links
{
    cursor: pointer;
    transition: .3s;
    font-size: 19px;
}
.header_technohood .nav-list .nav-links:hover
{
    color: #1998d8;
}

.header_technohood .extras-btns {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}
.header_technohood .responsive-popup-menu
{
    z-index: 1;
    position: absolute;
    top: 80px;
    background-color: rgb(255, 255, 255);
    color: black;
}

.header_technohood .responsive-menu-btn img
{
    width: 25px;
    height: 25px;
}

.header_technohood .extras-btns > button {
    border: none;
    border-radius: 3px;
}
.header_technohood .extras-btns .get-quote-btn{
  font-family: 'Bebas Neue', sans-serif;
    border: 1px solid #F2CC52;
    background: transparent;
    color: #F2CC52;
    letter-spacing: 1.2px;
    padding:0.4rem 1.7rem;
    font-size: 20px;
    transition: .3s;

}
.header_technohood .extras-btns .get-quote-btn:hover{
    background-color: #F2CC52;
    color: black;
    cursor: pointer;
}
.header_technohood .extras-btns .blue-btn
{
    padding:5px 15px;
    transition: .3s;
}
.header_technohood .extras-btns .blue-btn:hover{
    background-color: #1998d8;
    cursor: pointer;
}
.header_technohood .extras-btns .blue-btn img {
    width: 17px;
    margin-right: 1px;
    margin-top: 2px;
}

.header_technohood .resp-nav-list .nav-links 
{
    color: black;
    font-size: 1.4rem;
}

.header_technohood .resp-nav-list li{
    list-style: none;
}
.header_technohood .resp-nav-list
{
    width: 100%;
}

/* dropdown css */

.navigation-dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-trigger {
    cursor: pointer;
    color: white;
  }
  .dropdown-trigger img{
   width: 15px;
   height: 15px;
   margin-left: 5px;
  }
  
  .dropdown-content {
    position: absolute;
    top: 160%;
    left: 0%;
    background-color: white;
    /* min-width: 220px; */
    width: max-content;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1;
    border-radius: 10px;
    padding: 15px 20px;
     display: flex;
    flex-direction: column;
    align-items: start;
    gap: 10px;
  }

  .responsive-popup-menu .dropdown-content
  {
    position: relative!important;
  }

  .dropdown-content .nav-links p{
    color: black;
    list-style: none;
    text-decoration: none;
    font-weight: 500;
    font-size: 1.2rem;
  }
  .dropdown-content .nav-links p:hover{
    color: #1998d8;
  }
  
  
  /* Custom CSS Animation */
  .dropdown-content-enter {
    opacity: 0;
    transform: translateY(-10px);
  }
  
  .dropdown-content-enter-active {
    display: block;
    opacity: 1;
    transform: translateY(0);
    transition: opacity 300ms, transform 300ms;
  }
  
  .dropdown-content-exit {
    opacity: 1;
    transform: translateY(0);
  }
  
  .dropdown-content-exit-active {
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 300ms, transform 300ms;
  }

  .header_technohood .arrow-icon
  {
    width: 15px;
    height: 10px;
  }
  
  .rotate-icon {
    animation: rotate180 .3s ease-in-out forwards;
  }

  
  @keyframes rotate180 {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(180deg);
    }
  }
  
  /* Return to original position */
  .rotate-back {
    animation: rotateBack .1s ease-in-out forwards;
  }
  
  @keyframes rotateBack {
    from {
      transform: rotate(180deg);
    }
    to{
     transform: rotate(0deg);
   }
  }

  
  .none-delay{
    display: none;
  }

  @keyframes hide{
    from
    {
      display: flex;

    }
    to {
      display: none;
    } 
  }