body {
  margin: 0;
  font-family:"Bebas Neue", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #022A44;
  scroll-behavior: smooth; 
}
/*   
font-family: "Poppins", sans-serif;
font-family: "Bebas Neue", sans-serif; 
*/

.standard-margin-space {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.standard-padding-space {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.font-poppins
{
  font-family: "Poppins", sans-serif!important;
}

.font-bebus
{
  font-family: "Bebas Neue", sans-serif!important;
}
.font-bebas
{
  font-family: "Bebas Neue", sans-serif!important;
}

.color-yellow{
  color: #F2CC52!important;
}
.color-blue{
  color:  #3cade2!important;
}

.body-heading, .body-heading span, .hero-section-heading {
  font-family: 'Bebas Neue', sans-serif; 
  font-weight: 400;
  text-transform: uppercase;
  user-select: none;
}

.body-heading { color: #ffffff; font-size: 55px; }

.hero-section-heading { font-size: 72px; color: #ffffff;  }

.hero-section-heading span { font-size: 72px;  }

.body-heading span {  font-size: 55px; }

.large-para
{
  font-size: 23px!important;
}
 

.body-paragraph {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #CCCCCC;
  user-select: none;
}

.sub-heading { 
  color: #ffffff;
  font-weight: 500;
  font-size: 19px;
  text-transform: uppercase;
  font-family: 'Bebas Neue', sans-serif;
  user-select: none;
}
  
.blue-btn {
  font-family: 'Bebas Neue', sans-serif;
  padding: 0.4rem 2rem;
  background: #1eaef4;
  font-size: 20px;
  border: none;
  color: #ffffff;
  border-radius: 6px;
  cursor: pointer;
  transition: .3s;
}
.blue-btn:hover {
  background: #157bae;
}

.blue-btn span {
  margin-right: .4rem;
}

@media (max-width: 1250px) {
  .body-heading, .body-heading span {
    font-size: 42px;
  }

  .hero-section-heading, .hero-section-heading span {
    font-size: 60px;
  }
  .large-para
  {
    font-size: 16px!important;
  }
}

@media (max-width: 460px) {
  .body-heading, .body-heading span {
    font-size: 30px;
  }

  
 
  .hero-section-heading, .hero-section-heading span {
    font-size: 45px;
  }

  .body-paragraph {
    font-size: 15px;
  }
}

@media (max-width: 380px) {
  .body-heading, .body-heading span {
    font-size: 27px;
  }

  .hero-section-heading, .hero-section-heading span {
    font-size: 38px;
  }

  .body-paragraph {
    font-size: 14px;
  }

  .sub-heading {  
    font-size: 12px; 
  } 
}