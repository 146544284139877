.work-industry-container
{
    background-color: #022a44;
}

.work-industry-container .industry-list .industry-card
{
    display: flex;
    flex-direction:column ;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.work-industry-container .industry-list .industry-card .card-icon
{
    width: 50px;
    height: 50px;
}
.work-industry-container .industry-list .industry-card .card-icon-container
{
    border-radius: 20px;
    background-color: white;
    padding:20px;
    width: fit-content;
}

.work-industry-container .swiper-button-prev-ind
{
    position: absolute;
    top: 35%;
    left: 0;
    z-index: 10;
    cursor: pointer;

}
.work-industry-container .swiper-button-next-ind
{
    position: absolute;
    top: 35%;
    z-index: 10;
    right: 0;
    cursor: pointer;

}