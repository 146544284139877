.rs_popup_section {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 20000;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.073);
  backdrop-filter: blur(24px);
  overflow: auto;
}

.rs_popup_section .rs_popup_section_bg {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 30000;
}
.rs_popup_open {
  overflow: hidden;
}
.rs_popup_close {
  position: absolute;
  left: 100%;
  display: none;
  top: 0;
  border: 0;
  width: 40px;
  height: 40px;
  font-size: 13px;
  color: #000;
  margin-left: 5px;
  cursor: pointer;
  background: #fff;
  border-radius: 50%;
  line-height: 36px;
  text-align: center;
  box-sizing: border-box;
}
.popup_contents {
  display: block;
  position: fixed;
  width: auto;
  max-width: 60%;
  margin: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  background: transparent;
  z-index: 40000;
}
@media(max-width:992px){
  .popup_contents{
    max-width: 90%;
  }
}