.partnerCompanies_container .companies-logo-box
{
    background-color: #022A44;
    padding-top: 4rem;
    padding-bottom: 4rem;
    user-select: none;
}

.partnerCompanies_container .container
{
    /* display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap:40px; */
} 
.partnerCompanies_container .companies-logo-box img
{
    /* width: 184px;
    height: 37px; */
    margin: auto;
}
.partnerCompanies_container .companies-logo-box .company-icon
{
    width: 90%;

}

.partnerCompanies_container .swiper-slide
{
    display: flex;
    align-items: center;
    justify-content: center;
}