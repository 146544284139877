.CaseStudiesList-container
{

}

.CaseStudiesList-container .case-st-card
{
    margin-bottom: 90px;
}

.CaseStudiesList-container .case-st-card-anchor
{
    color: white;
    transition: .3s;
}
.CaseStudiesList-container .case-st-card-anchor:hover
{
    cursor: pointer;
    color: #3CADE2;
}

.CaseStudiesList-container .case-st-card-icon
{
    width: 22px;
    height: 22px;
}

.CaseStudiesList-container .second .case-st-card
{
    transform: translateY(80px);
}

@media(max-width:992px)
{
    .CaseStudiesList-container .case-st-card
    {
        margin-bottom: 60px;
    }
    .CaseStudiesList-container .second .case-st-card
    {
        transform: translateY(0px);
    }
}