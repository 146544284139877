.Hero {
  width: 100%;
  min-height: 90vh;
  max-height: 90vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding-bottom: 30px;
}

.overlay{
  width: 100%;
  height: 100%;
  z-index: 0;
  position: absolute;
  top: 0;
  object-fit: cover;
  background-color:rgba(0, 0, 0, 0.36);
  padding-bottom: auto;
}

.video {
  width: 100%;
  height: 100%;
  z-index: -1;
  position: absolute;
  top: 0;
  object-fit: cover;
  /* background-image: linear-gradient(to right, rgb(25, 25, 25), rgb(0, 0, 0)); */
  padding-bottom: auto;
}
.Hero_Content {
  max-width: 1320px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 25px;
  flex-grow: 1;
  position: relative;
  z-index: 1;
}
.Hero_Content h1 {
  font-weight: 900;
  color: white;
  letter-spacing: 3px;
  text-align: center;
  align-self: center;
  font-size: 88px;
}
.Hero_Content h3 {
  text-align: center;
  align-self: center;
  color: white;
  letter-spacing: 4px;
}
.logosCont {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  height: 120px;
}
.swiperSlide {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
}
.swiperSlide img {
  width: 200px;
  max-height: 200px;
}
@media (max-width: 1200px) {
  .Hero_Content h1 {
    font-size: 55px;
  }

}
@media (max-width: 767px) {
  .Hero {
    min-height: 80vh;
    max-height: 80vh;
  }
  /* .video {
    height: 70%;
  } */
  .Hero_Content h1 {
    padding: 0 3% 0 3%;
  }
  .Hero_Content h3 {
    padding: 0 20% 0 20%;
  }
  .Hero_Content h1 {
    font-size: 35px;
  }
  .Hero_Content h3 {
    font-size: 16px;
  }
}
@media (min-height: 1100px) {
  .video {
    height: 65%;
  }
}