.Testimonials-container
{
    background-image: url("../../../public/images/creatives/testimonial-sec-bg.svg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    height: 40em;
    background-color: hsl(203, 92%, 10%);
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    padding: 3em;
}

.Testimonials-container .text-content
{
    color: white;
}

.Testimonials-container .text-content .body-paragraph
{
    padding-right: 20px;
}

.Testimonials-container .testimonial-card
{
    background-color: white;
    padding: 15px 25px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    min-height: 300px;
    cursor: pointer;

}
.Testimonials-container .testimonial-card .quote-img
{
    width: 2.7em;
}

.Testimonials-container .testimonial-card p
{
    line-height: 1.5;
    margin: auto 0;
    white-space: normal;
}

.Testimonials-container .profile-info
{
    display: flex;
    align-items: center;
    gap: 15px;
}

.Testimonials-container .profile-info .user-img{
    border-radius: 50%;
    width: 50px;
}

.Testimonials-container .profile-info .rating-img{
    width: 100px;
}


.Testimonials-container .profile-info h5{
   font-size: 1.3rem;
   font-weight: 600;
   letter-spacing: 1px;
}

.Testimonials-container .arrow-btn
{
    background: transparent;
    border-radius: 50%;
    padding:5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid white;
}
.Testimonials-container .arrow-btn img
{
   width: 15px;
   height: 15px;
}

@media (max-width: 730px) {
    .Testimonials-container
    {
        flex-direction: column;
        height: auto;
    }
    .Testimonials-container .text-content
    {
        width: 80%;
    }
    .Testimonials-container .testimonial-card
    {
        min-height: 300px;
    }
    .Testimonials-container .text-content .body-paragraph
    {
        padding-right: 0;
    }
}