.BussinessForm-container{

}


.BussinessForm-container .service-form
{
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  width: 90%;
  background-color: #b5b5b52a;
  border-radius: 10px;
  color: white;
  border:1px solid white;
}
.BussinessForm-container .service-form .body-paragraph,
.BussinessForm-container .service-form .body-heading
{
 cursor: pointer;
}


.BussinessForm-container .service-form input
{
  border: none;
  background: transparent;
  color: white;
  border-bottom: 1px solid white;
  margin-top: 20px;
  padding-bottom: 10px;

}
.BussinessForm-container .service-form input::placeholder,
.BussinessForm-container .service-form input[type="date"]
{
  color: rgba(225, 225, 225, 0.873);

}
.BussinessForm-container .service-form input:focus
{
  outline: none;
}

.BussinessForm-container .list-bullet
{
    width: 20px;
    height: 20px;
}

.BussinessForm-container input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.BussinessForm-container .file-input-wrapper {
  display: inline-block;
  background-color: transparent; /* Set the background color */
  border: 1px solid white;
  color: #fff; /* Set the text color */
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
}

/* Hide the original file input */
.BussinessForm-container .file-input-wrapper input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

.BussinessForm-container input[type="number"]::-webkit-outer-spin-button,
.BussinessForm-container input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.BussinessForm-container input[type="number"] {
  -moz-appearance: textfield;
}