.whychooseus-container
{
    background-color: #022A44;
}

.whychooseus-container .color-change-blue
{
    color: #1EAEF4;
}

.whychooseus-container .text-content h3
{
    color: white;

}
.whychooseus-container .text-content .body-paragraph
{
    width: 80%;
    margin: auto;
}


.whychooseus-container .whychooseus-card-box
{
    background-color: #021F31;
    color: white;
    width: 100%;
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    justify-content: center;
    gap: 15px;
    padding: 40px 20px;
    padding-bottom: 20px;
    transition: .3s;
    height: 100%;
}
.whychooseus-container .whychooseus-card-box:hover
{
    background-color: #04314e;
    border: 1px solid white;
    color: white;
    cursor: pointer;
}

.whychooseus-container .whychooseus-card-box .card-link
{
    color:#1EAEF4;
    font-size: 18px;
    text-decoration: underline;
    transition: .3s;
}
.whychooseus-container .whychooseus-card-box .card-link:hover
{
    color:#096491;
}
.whychooseus-container .whychooseus-card-box .card-title
{
    font-size: 23px;
    color: white;

}

.whychooseus-container .whychooseus-card-box img{
    width: 70px;
    height: 70px;
    background-color: #022A44;
    padding: 15px;
    border-radius: 50%;
}

.whychooseus-container .swiper-slide
{
    height: auto;
}
.whychooseus-container .swiper-slide div
{
    height: 100%;
}

@media (max-width: 1200px)
{
    /* .whychooseus-container .whychooseus-card-box p
    {
        height: 2rem;
    }    */
} 