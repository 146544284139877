.about-paralalax-effect
{
    background: linear-gradient( #022a44f8, #022a4489,#022a44eb), 
    url("../../../public/images/creatives/why-us-sec-bg.png") center center/cover no-repeat fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #022a44;
    padding:70px 0px;
}

.about-paralalax-effect .para-img_by_cn_section {

    background-size: cover;
    background-repeat: no-repeat;
    padding-bottom: 60px;
    padding-top: 60px;

}

.about-paralalax-effect .para-img_by_cn_section .image-wrapper {
    padding-right: 1.5rem;
}
  
.about-line-heading .img-cn-main-heading{ 
    color: #FFF;  
    margin-bottom: 20px;
}
 
.about-paralalax-effect .para-img_by_cn_section .responsove-width
{
    width: 90%;
}

.about-paralalax-effect .para-img_by_cn_section .whychooseus-card-box
{
    background-color: #021F31;
    color: white;
    width: 95%;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    gap: 15px;
    padding: 40px 20px;
    transition: .3s;
}
.about-paralalax-effect .para-img_by_cn_section .whychooseus-card-box .card-text
{
    text-align: start;
}

.about-paralalax-effect .para-img_by_cn_section .whychooseus-card-box:hover
{
    background-color: #04314e;
    border: 1px solid white;
    color: white;
    cursor: pointer;
}

.about-paralalax-effect .para-img_by_cn_section .whychooseus-card-box img{
    width: 70px;
    height: 70px;
    margin: auto 10px;
}

.about-paralalax-effect .para-img_by_cn_section .whychooseus-card-box .card-heading
{
    color: #F2CC52;

}

.about-paralalax-effect .para-img_by_cn_section .whychooseus-card-box .body-paragraph
{
    font-size: .7rem;
}

@media (max-width : 768px)
{
    .about-paralalax-effect .para-img_by_cn_section .responsove-width
    {
        width: 100%;
    }
    .about-paralalax-effect .para-img_by_cn_section .image-wrapper {
        padding: 1.5rem;
    }
}