.ServicesCarasoul-container
{
}

.ServicesCarasoul-container .main-heading
{
  text-align: center;
  width: 50%;
  margin: auto;
}
.ServicesCarasoul-container .main-heading .heading-color-change
{
  color: #E1A92A;
}



.ServicesCarasoul-container .service-card
{
    width: 100%;
    
        
    border-radius: 13px;
    background-color: #021f31;
    height: 100%;
}
.ServicesCarasoul-container .service-card .service-card-info,
.ServicesCarasoul-container .service-card .profile-info
{
    /* height: 100%; */
    
}


.ServicesCarasoul-container .genral-btn
{
    background-color: #E1A92A;
    width: fit-content;
    margin: auto;
}
.ServicesCarasoul-container .service-card .review-text
{
    color:white;
    font-size: 14px!important;
}

.ServicesCarasoul-container .swiper-button-prev,
.ServicesCarasoul-container .swiper-button-next
{
    color: #848484!important;
}

.ServicesCarasoul-container .swiper-button-prev
{
    position: absolute;
    top: 45%;
    left: 0;
    z-index: 10;
    cursor: pointer;

}
.ServicesCarasoul-container .swiper-button-next
{
    position: absolute;
    top: 45%;
    z-index: 10;
    right: 0;
    cursor: pointer;

}


.ServicesCarasoul-container .swiper-slide
{
    height: auto;
}

.ServicesCarasoul-container .arrow-img
{
    height: 30px;
    width: 30px;
}

@media(max-width:660px)
{
    .ServicesCarasoul-container .main-heading
{
  width: 80%;
}
}