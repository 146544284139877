.CarrersForm-container{

}


.CarrersForm-container .service-form
{
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  width: 90%;
  background-color: #b5b5b52a;
  border-radius: 10px;
  color: white;
  border:1px solid white;
}
.CarrersForm-container .service-form .body-paragraph,
.CarrersForm-container .service-form .body-heading
{
 cursor: pointer;
}


.CarrersForm-container .service-form input,
.CarrersForm-container .service-form select
{
  border: none;
  background: transparent;
  color: white;
  border-bottom: 1px solid white;
  margin-top: 28px;
  padding-bottom: 10px;

}
/* .CarrersForm-container .message-box{
  height: 100px;
  border: 1px solid white!important;
} */
.CarrersForm-container .service-form input::placeholder,
.CarrersForm-container .service-form input[type="date"]
{
  color: rgba(225, 225, 225, 0.873);

}
.CarrersForm-container .service-form input:focus,
.CarrersForm-container .service-form select:focus
{
  outline: none;
}

.CarrersForm-container .list-bullet
{
    width: 20px;
    height: 20px;
}

.CarrersForm-container input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.CarrersForm-container .file-input-wrapper {
  display: inline-block;
  background-color: transparent; /* Set the background color */
  border: 1px solid white;
  color: #fff; /* Set the text color */
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
}

/* Hide the original file input */
.CarrersForm-container .file-input-wrapper input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

.CarrersForm-container input[type="number"]::-webkit-outer-spin-button,
.CarrersForm-container input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.CarrersForm-container input[type="number"] {
  -moz-appearance: textfield;
}

.CarrersForm-container option{
  color: black;
}