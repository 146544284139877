.footer-container
{
    background-color: black;
    padding-top: 30px;
    color: white;
}

.footer-container .line-seprator
{
    border: 1px solid rgba(255, 255, 255, 0.59);
    width: 100%;
    margin:20px auto;
}

/* Upper container */

.footer-container .upper-newsletter-container
{
    display: flex;
    justify-content: space-between;
    padding: 30px 0px;
}

.footer-container .upper-newsletter-container .text-container
{
    display: flex;
    align-items: center;
    gap: 14px;
}

.footer-container .upper-newsletter-container .text-container .newsletter-logo
{
    padding: 30px;
    background-color:#1EAEF4 ;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer-container .upper-newsletter-container .newsletter-logo img{
    width: 50px;
    height: 50px;
}
.footer-container .upper-newsletter-container .newsletter-form
{
    display: flex;
    align-items: center;
}
.footer-container .upper-newsletter-container .newsletter-form input
{
    border: none;
    border-radius: 12px;
    padding:20px 15px;
    padding-left: 25px;
    width:25em;

}
.footer-container .upper-newsletter-container .newsletter-form input:focus
{
    outline: none;
}
.footer-container .upper-newsletter-container .newsletter-form button
{
    display: flex;
    align-items: center;
    gap: 10px;
    border: none;
    position: relative;
    right: 10%;
    border-radius: 12px;
    background-color: #1EAEF4;
    color: white;
    padding:18px 20px;
    font-weight: 600;
    font-size: 1.25rem;
    transition: .3s;
}

.footer-container .upper-newsletter-container .newsletter-form button img{
    width: 15px;
    margin-top: 5px;
}

.footer-container .upper-newsletter-container .newsletter-form button:hover{
    cursor: pointer;
    background-color: #147aad;
}

.footer-container .upper-newsletter-container .text-container .text-content p
{
    font-size: small;
    color: #CCCCCC;
}
.footer-container .upper-newsletter-container .text-container .text-content h3
{
    margin-bottom: 5px;
    font-weight: 700;
    font-size: 1.3rem;
    color: white;
}

.footer-container .upper-newsletter-container .text-container{
    width: 40%;
}

/* Middle link Container */

.footer-container .middle-links-container
{
    display: flex;
    /* justify-content: start; */
    padding: 30px 0;
}

.footer-container .middle-links-container .left
{
    width: 30%;
    /* margin-right: auto; */
    margin-right: 20px;
}

.footer-container .middle-links-container .right
{
    /* display: flex;
    flex-direction: row;
    justify-content: space-between; */
    padding: 10px 0;
    margin-right: 10px;

}

/* .footer-container .middle-links-container .right-upper
{
    display: flex;
    flex-direction: column;
    font-size: normal;
    padding-left: 0;
} */
.footer-container .middle-links-container .right-upper li
{
    list-style: none;
    cursor: pointer;
    font-size: normal;
}
.footer-container .middle-links-container .right-upper li:hover{
    color: #CCCCCC;
}
.footer-container .middle-links-container .right-bottom
{
    gap: 30px;
    row-gap: 25px;
    padding-left: 0;
}
.footer-container .middle-links-container .right-bottom li
{
    display: flex;
    align-items: center;
    gap: 5px;
}

.footer-container .middle-links-container .righ .right-bottom li a
{
    color: white;
    text-decoration: none;
}
.footer-container .middle-links-container .right-bottom li a
{
    color: white;
    text-decoration: none;
}
.footer-container .middle-links-container .right-bottom li
{
    list-style: none;
    cursor: pointer;
}
.footer-container .middle-links-container .righ li
{
    list-style: none;
    cursor: pointer;
}
.footer-container .middle-links-container .right-bottom li a:hover{
    color: #CCCCCC;
}

/* Lower Container */

.footer-container .lower-container
{
    background-color: white;
    padding:15px 0;
    color: #9B9B9B;
}
.footer-container .lower-container small a 
{
    text-decoration: none;
    color: #9B9B9B;
}

.footer-container .lower-link-list
{
    display: flex;
    margin-bottom: 0;
    gap: 15px;
    padding-left: 0;
}
.footer-container .lower-link-list li
{
    list-style: none;
}
.footer-container .lower-link-list li img
{
   width: 25px;
}
.footer-container .lower-link-list li img:hover
{
   cursor: pointer;
}

@media(max-width : 1050px)
{
    .footer-container .upper-newsletter-container
    {
        flex-direction: column;
    }
    .footer-container .upper-newsletter-container .text-container
    {
        flex-direction: column;
        text-align: center;
    }
    .footer-container .upper-newsletter-container .text-container,.newsletter-form
    {
        margin: auto;
        margin-bottom: 15px;
    }
    .footer-container .middle-links-container .right .right-upper
    {
        padding: 0;
        font-size: small;
    }
    .footer-container .upper-newsletter-container .newsletter-form button
    {
        right: 50px;
    }
}

@media(max-width:992px)
{
    .footer-container .right-upper
    {
        padding-left: 0!important;
    }
}

@media(max-width :768px)
{
    
    .footer-container .middle-links-container .left
    {
        margin-right: 0;
    }
}

@media (max-width: 730px) {
    
    .footer-container .middle-links-container
    {
        flex-direction: column;
        align-items: center;
    }
    .footer-container .upper-newsletter-container .text-container 
    {
        width: 100%;

    }
    .footer-container .upper-newsletter-container .newsletter-form
    {
        justify-content: center;
    }
    .footer-container .middle-links-container .right
    {
        /* width: 90%; */
    }
    .footer-container .middle-links-container .left
    {
        width: 60%;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 20px;
    }
    .footer-container .upper-newsletter-container .newsletter-form
    {
        width: 70%;
        padding:15px 15px;
        position: relative;
    }
    .footer-container .upper-newsletter-container .newsletter-form input
    {
        width: 100%;
        padding: 7px 10px;
        margin: auto;

    }
    .footer-container .upper-newsletter-container .newsletter-form button
    {
        padding: 10px 12px;
        position: absolute;
        right:15px;
    }
    .footer-container .upper-newsletter-container .newsletter-form button img
    {
        margin-top: 0;
    }
    .footer-container .middle-links-container .right .right-bottom
    {
        margin-top: 20px;
    }
    
    /* .footer-container .lower-container
    {
        padding-left: 10px;
        padding-right: 10px;
    } */

    .footer-container .upper-newsletter-container .newsletter-form .subscribe-btn-text
    {
        display: none;
    }
}

@media(max-width:365px)
{
    .footer-container .middle-links-container .right .right-upper
    {
        font-size:x-small;
    }
}