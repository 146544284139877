.list-card-container
{
    background: #022a44;
}
.list-card-container .container-width
{
}

.list-card-container .list-card-box
{
    border-radius: 13px;
    background-color: #021f31;
    height: 100%;
}

.list-card-container .list-card-box .title
{
    font-size: 28px;
    font-weight: 600;
    color: white;
}
.list-card-container .list-card-box
{
    
}
.list-card-container .list-bullet
{
    width: 20px;
    height: 20px;
    margin-top: 2px;
}
.list-card-container .list-card-box .card-icon
{
    width: 50px;
    height: 50px;
    background-color: #022a44;
    padding: 10px;
    border-radius: 5px;
}

@keyframes bottomBoxOpenCard {
    from{
        opacity: 0.2;
        transform: translateY(-3px);
    }
    to{
        opacity: 1;
       transform: translateY(0px);
    }
}
.list-card-container .list-card-box .close
{
    animation: bottomBoxCloseCard .3s ease-in-out;
}

@keyframes bottomBoxCloseCard {
    from{
        transform: translateY(3px);
    }
    to{
       transform: translateY(0px);
    }
}
