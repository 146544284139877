.IconCardCarasoul-container
{
}

.IconCardCarasoul-container .main-heading
{
  text-align: center;
  width: 50%;
  margin: auto;
}
.IconCardCarasoul-container .main-heading .heading-color-change
{
  color: #E1A92A;
}



.IconCardCarasoul-container .service-card
{
    width: 100%;
    
        
    border-radius: 13px;
    background-color: #021f31;
    height: 100%;
}
.IconCardCarasoul-container .service-card .service-card-info,
.IconCardCarasoul-container .service-card .profile-info
{
    /* height: 100%; */
    
}


.IconCardCarasoul-container .genral-btn
{
    background-color: #E1A92A;
    width: fit-content;
    margin: auto;
}
.IconCardCarasoul-container .service-card .review-text
{
    color:white;
    font-size: 14px!important;
}

.IconCardCarasoul-container .swiper-button-prev,
.IconCardCarasoul-container .swiper-button-next
{
    color: #848484!important;
}

.IconCardCarasoul-container .swiper-button-prev
{
    position: absolute;
    top: 45%;
    left: 0;
    z-index: 10;
    cursor: pointer;

}
.IconCardCarasoul-container .swiper-button-next
{
    position: absolute;
    top: 45%;
    z-index: 10;
    right: 0;
    cursor: pointer;

}


.IconCardCarasoul-container .swiper-slide
{
    height: auto;
}

.IconCardCarasoul-container .arrow-img
{
    height: 30px;
    width: 30px;
}
.IconCardCarasoul-container .icon-card-icon
{
    height: 80px;
    width: 80px;
    padding: 10px;
    background-color: #022a44;
    border-radius: 50%;
}


@media(max-width:660px)
{
    .IconCardCarasoul-container .main-heading
{
  width: 80%;
}
}