
body{
  background-color: #022A44;
}
p,h1,h2,h3,h4,h5,h6,a,button,img,small
{
    user-select: none!important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.adjustable-small-width{
  max-width: 75%;
}

@media(max-width:992px)
{
  .adjustable-small-width{
    max-width: 100%;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/*  video modal */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-overlay .modal-content {
  width: 75%;
  max-width: 80%;
  background: #000000e8;
  padding: 5px;
  border-radius: 8px;
}

.modal-overlay .modal-video {
  max-width: 100%;
  max-height: 90vh;
  /* object-fit: contain; */
}

.modal-overlay video {
  width: 100%;
  height: auto;
  display: block;
}


.modal-overlay {
  touch-action: none;
}

@media(max-width:993px)
{
  .modal-overlay .modal-content {
    width: 90%;
    max-width: 90%;
    background: #000000e8;
    padding: 5px;
    border-radius: 8px;
  }
}