.CardTestimonial-container
{
    background: linear-gradient( #022a44f8, #022a4452,#022a44eb), 
    url("../../../public/images/creatives/blog-sec-bg.png") center center/cover no-repeat fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #022a44;
    padding:50px 0px;
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

}
.CardTestimonial-container .card-box
{
    background-color: #021F31;
    color: white;
    padding: 5px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 16px;
    cursor: pointer;
}

.CardTestimonial-container .card-box .card-text-content
{
    width: 93%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: auto;
}

.CardTestimonial-container .card-box h5{
    color: #CCCCCC;
    font-size: 17px;
    font-weight: 500;
    min-height: 2.3rem;
}

.CardTestimonial-container .card-box small{
    color: #8B8B8B;

}

.CardTestimonial-container .card-box a
{
    color: #3CADE2;
    text-decoration: underline;
    transition: .3s;
    font-weight: 400;

}

.CardTestimonial-container .card-box a:hover{
    cursor: pointer;
    color: #07618b;
}

.CardTestimonial-container .card-box img{
    width: 95%;
    margin:5% auto;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 15px;
}

.CardTestimonial-container .testimonial-text-content
{
    color: white;
    margin-bottom: 60px;
    text-align: center;
}

.CardTestimonial-container .card-list
{
    margin: auto;
    width: 100%;
}

.CardTestimonial-container .btn-container
{
    margin-top: 40px;
}