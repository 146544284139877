.grid-card-container
{
    background: #022a44;
}
.grid-card-container .container-width
{
}

.grid-card-container .grid-card-box
{
    border-radius: 13px;
    background-color: #021f31;
    height: 100%;
}

.grid-card-container .grid-card-box .title
{
    font-size: 28px;
    font-weight: 600;
    color: white;
}
.grid-card-container .grid-card-box
{
    transition:height .5s;
}
.grid-card-container .grid-card-box .open
{
    animation: bottomBoxOpenCard .6s ease-in-out;
}

@keyframes bottomBoxOpenCard {
    from{
        opacity: 0.2;
        transform: translateY(-3px);
    }
    to{
        opacity: 1;
       transform: translateY(0px);
    }
}
.grid-card-container .grid-card-box .close
{
    animation: bottomBoxCloseCard .3s ease-in-out;
}

@keyframes bottomBoxCloseCard {
    from{
        transform: translateY(3px);
    }
    to{
       transform: translateY(0px);
    }
}
