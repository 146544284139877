.common_hero_section {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 500px;
  width: 100%;
  z-index: 0;
}
.color-white
{
  color: white;
}



/* just to increase the min height to make the contact form more prominent*/
.hero-social-links-height
{
  min-height: 750px;
}

.common_hero_section .social-links, .common_hero_section .hero-text-content
{
  cursor: pointer;
}

.common_hero_section .bg-effect
{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: linear-gradient( #022a44, #022a4433,#022a44); */
 
  /* background: linear-gradient(
    #022a44,       
    #022a4429 20%, 
    #022a4441 80%, 
    #022a44        
  );
background-repeat: no-repeat;
background-size: cover;
background-position: center center;
  z-index:-1 ; */


}


.common_hero_section .service-form
{
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  width: 90%;
  background-color: #b5b5b52a;
  border-radius: 10px;
  color: white;
  border:1px solid white;
}
.common_hero_section .service-form .body-paragraph,
.common_hero_section .service-form .body-heading
{
 cursor: pointer;
}

.bg-video
{
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  /* background-color: #022a447d; */
}

.bg-video video{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.common_hero_section .service-form .blue-btn
{
  width: fit-content;
  color: white;
  /* background-color: #F2CC52; */
}
.common_hero_section .service-form .blue-btn:hover
{
 /* background-color: #cfae42; */
}
.common_hero_section .service-form input
{
  border: none;
  background: transparent;
  color: white;
  border-bottom: 1px solid white;
  margin-top: 20px;
  padding-bottom: 10px;

}
.common_hero_section .service-form input::placeholder
{
  color: rgba(225, 225, 225, 0.873);

}
.common_hero_section .service-form input:focus
{
  outline: none;
}

.common_hero_section .social-links .link-container p
{
  color: white;
}

.hero_content_wrapper .hero-text-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  width: 100%;
}

.hero_content_wrapper .hero-text-content .hero-section-heading,
.hero_content_wrapper .hero-text-content .body-paragraph {
  text-align: center;
}

.hero_content_wrapper .hero-text-content .hero-section-heading
{
  max-width: 100%;
}


@media(max-width : 1200px)
{
  .bg-video
{
  height: auto;
}
}
@media(max-width : 660px)
{
  .hero_content_wrapper .hero-text-content 
  {
    max-width: 80%;
    margin: auto;
  }
  .hero-social-links-height
  {
      min-height: 1000px;
  }
}